


















































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Meeting } from '@tada-team/tdproto-ts'
import { teamsStore } from '@/store'

@Component({
  components: {
    MeetingCardActions: () => import('./components/Actions.vue'),
    MeetingCardMainInfo: () => import('./components/MainInfo.vue'),
    MeetingTooltip: () => import('./components/Tooltip.vue'),
  },
})
export default class MeetingCardCompact extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) private readonly meeting!: Meeting

  @Prop({
    type: Boolean,
    default: false,
  }) private readonly isShowIcon!: boolean

  @Prop({
    type: Boolean,
    default: false,
  }) private readonly isShowInterval!: boolean

  @Prop({
    type: Boolean,
    default: false,
  }) private readonly isShowName!: boolean

  @Prop({
    type: Boolean,
    default: false,
  }) private readonly narrowMode!: boolean

  @Prop({
    type: Boolean,
  }) isCalendarCard?: boolean

  @Prop({
    type: Boolean,
    default: false,
  }) readonly isShowActions!: boolean

  get isBusy () {
    return this.meeting.meetingRecipientStatus !== 'MEETING_RECIPIENT_STATUS_MINE'
  }

  get canShowActions () {
    if (!this.isShowActions) return
    return this.meeting.canDelete || this.meeting.canEdit
  }

  private openMeeting (event: MouseEvent): void {
    if ((event.ctrlKey || event.shiftKey || event.metaKey) && !this.isBusy) {
      return
    }
    event.preventDefault()
    if (this.isBusy) return
    this.$router.push({
      name: 'Chat',
      params: {
        jid: this.meeting.jid,
        teamId: teamsStore.getters.currentTeam.uid,
      },
    })
  }

  get currentTeamId () {
    return teamsStore.getters.currentTeam.uid
  }
}
