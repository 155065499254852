var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-card',{staticClass:"column meeting-card-compact td-rounded-8 redesign",class:{
    'meeting-card-compact__required cursor-pointer': !_vm.isBusy,
    'meeting-card-compact__busy cursor-inherit': _vm.isBusy,
  },attrs:{"flat":"","tag":"a","href":("/" + _vm.currentTeamId + "/chats/" + (_vm.meeting.jid) + "/")},on:{"click":function (event) { return _vm.openMeeting(event); }}},[_c('div',{staticClass:"row items-center justify-between full-width no-wrap",class:{
      'meeting-card-compact__height': _vm.isShowActions
    }},[_c('MeetingCardMainInfo',{attrs:{"meeting":_vm.meeting,"is-show-icon":_vm.isShowIcon,"is-show-interval":_vm.isShowInterval,"narrow-mode":_vm.narrowMode,"type":"primary"}}),(_vm.canShowActions)?_c('MeetingCardActions',{class:{
        'meeting-card-actions': !_vm.isShowActions
      },attrs:{"meeting":_vm.meeting}}):_vm._e()],1),(_vm.isShowName)?_c('div',{staticClass:"row items-start justify-between full-width meeting-card-full__head no-wrap"},[_c('q-item-label',{staticClass:"meeting-card-full__title",attrs:{"lines":"1"}},[_vm._v(" "+_vm._s(_vm.meeting.displayName)+" ")])],1):_vm._e(),(_vm.isCalendarCard)?_c('MeetingTooltip',{attrs:{"meeting":_vm.meeting}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }